package app.templates

import app.ApplicationDataStore
import app.SearchResults
import app.VariationStore
import dev.fritz2.core.*
import model.Card
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement

fun RenderContext.search(
  store: ApplicationDataStore
): Tag<HTMLElement> =
  section(baseClass = "big-nes-container with-title") {
    input(baseClass = "search-box") {
      type("search")
      placeholder("Search and press Enter")
      changes.values() handledBy {
        store.submitSearch(it)
      }
      focuss handledBy {
        (it.target as? HTMLInputElement)?.select()
      }
    }
    store.search.data.render {
      when (it) {
        is SearchResults.None -> i { + "Patiently waiting for your search" }
        is SearchResults.Loading -> searchLoading()
        is SearchResults.Found -> when {
          it.cards.isEmpty() -> noResults()
          else -> searchResults(store, it.cards)
        }
      }
    }
  }

fun RenderContext.searchLoading(): Tag<HTMLElement> =
  img(baseClass = "search-image") {
    src("https://media.tenor.com/oe1Vz5Ljqn8AAAAC/pokemon-mew.gif")
  }

fun RenderContext.noResults(): Tag<HTMLElement> =
  img(baseClass = "search-image") {
    src("https://media.tenor.com/Vpr8P1wbepcAAAAd/pokemon-ruby-pokemon-sapphire.gif")
  }

fun RenderContext.searchResults(
  store: ApplicationDataStore,
  cards: List<Card>
): Tag<HTMLElement> =
  div(baseClass = "search-results-box") {
    cards.forEach { card ->
      card(store.showImages, card) {
        addCardButton(card, "Main", "rule-book", store.main)
        addCardButton(card, "Support", "rare-candy", store.support)
      }
    }
    (1 .. 5).forEach { _ ->
      div(baseClass = "space-filler") { }
    }
  }

fun RenderContext.addCardButton(
  card: Card,
  name: String,
  icon: String,
  store: VariationStore
): Tag<HTMLElement> =
  button(baseClass = "add-deck-button") {
    img(baseClass = "deck-image-small") {
      src("assets/trainer/$icon.png")
    }
    title("Add to $name Deck")
    clicks handledBy {
      println("Adding ${card.name}")
      store.updateAmount(card.id to {
        it?.let { it + 1 } ?: 1
      })
    }
  }
