package app.templates

import dev.fritz2.core.*
import model.*
import org.w3c.dom.HTMLElement

fun RenderContext.card(
  showImages: Store<Boolean>,
  card: Card,
  additionalHeader: RenderContext.() -> Tag<HTMLElement>,
): Tag<HTMLElement> =
  details {
    summary {
      cardHeader(card, additionalHeader)
    }
    showImages.data.render {
      if (it) { cardImage(card) }
    }
    cardInfo(card)
    p { + "" } // space at the end
  }

fun HtmlTag<HTMLElement>.cardHeader(
  card: Card,
  additionalHeader: RenderContext.() -> Tag<HTMLElement>,
) {
  span {
    additionalHeader()
    card.imageRoute()?.let {
      img(baseClass = "poke") {
        src(it)
      }
    }
    shownName(card)
    img(baseClass = "set-symbol") {
      src(card.set.images.symbol)
      title(card.set.name)
    }
    for (strike in STRIKES) {
      if (card.subtypes.contains(strike)) {
        img(baseClass = "strike-symbol") {
          val image = strike.lowercase().replace(' ', '-')
          src("assets/trainer/$image.png")
          title(strike)
        }
      }
    }
  }

  span {
    inlineStyle("float: right")
    card.types?.let(::energySymbol)
    when (card.hp) {
      null -> span(baseClass = "mini-info") {
        + (card.subtypes - STRIKES).joinToString(separator = ", ")
      }
      else -> + "${card.hp} HP"
    }
  }
}

fun RenderContext.cardImage(
  card: Card
): HtmlTag<HTMLElement> =
  img(
    baseClass = when (card.supertype) {
      Supertype.ENERGY -> "card-image-energy"
      else -> "card-image"
    }
  ) {
    src(card.images.small)
  }

fun HtmlTag<HTMLElement>.cardInfo(
  card: Card
) {
  (card.abilities.orEmpty() + listOfNotNull(card.ancientTrait)).forEach { ability ->
    p(baseClass = "ability-text") {
      span(baseClass = "attack-name") { +ability.name }
      +": "
      withIcons(ability.text)
    }
  }
  card.attacks?.forEach { attack ->
    p(baseClass = "rule") {
      when {
        attack.cost.isEmpty() -> noEnergy()
        else -> energySymbol(attack.cost)
      }

      span(baseClass = "attack-name") { + attack.name }
      if (attack.damage != null) {
        span {
          inlineStyle("float: right")
          + attack.damage
        }
      }
    }
    if (attack.text.isNotBlank()) {
      p(baseClass = "attack-text") { withIcons(attack.text) }
    }
  }
  card.rules?.forEach { rule ->
    p(baseClass = "rule-text") {
      when {
        rule.contains("rule:") || rule.contains("Rule:") -> {
          val elements = rule.split(':', limit = 2)
          i { + "${elements[0]}:" }
          withIcons(elements[1])
        }
        else -> withIcons(rule)
      }
    }
  }
}

fun RenderContext.noEnergy() =
  span(baseClass = "no-energy-symbol") {
    + "⊚"
  }

fun RenderContext.energySymbol(type: List<Type>) =
  span(baseClass = "energy-symbol") {
    type.forEach {
      span(baseClass = it.name.lowercase()) {
        +"${it.usingFont}"
      }
    }
  }

fun HtmlTag<HTMLElement>.shownName(card: Card) {
  when (card.supertype) {
    Supertype.POKEMON -> {
      if ("MEGA" in card.subtypes) {
        span(baseClass = "font-symbol") { + "m" }
      }
      + card.baseName
      when {
        card.name.endsWith(" ex") || card.name.endsWith("-ex") ->
          span(baseClass = "font-symbol") { + "e" }
        card.name.endsWith(" EX") || card.name.endsWith("-EX") ->
          span(baseClass = "font-symbol") { + "E" }
        card.name.endsWith(" GX") || card.name.endsWith("-GX") ->
          span(baseClass = "font-symbol") { + "X" }
        card.name.endsWith(" V") ->
          img(baseClass = "special-symbol") { src("assets/fonts/v.png") }
        card.name.endsWith(" VMAX") ->
          img(baseClass = "special-symbol") { src("assets/fonts/vmax.png") }
        card.name.endsWith(" VSTAR") ->
          img(baseClass = "special-symbol") { src("assets/fonts/vstar.png") }
        card.name.endsWith(" V-UNION") ->
          img(baseClass = "special-symbol") { src("assets/fonts/vunion.png") }
      }
    }
    else -> span(baseClass = "name") {
      val things = card.name.split(" (")
      when (things.size) {
        1 -> withIcons(things.first())
        2 -> {
          withIcons(things.first())
          + " "
          span(baseClass = "subname") {
            withIcons(things[1].dropLast(2).replace("Professor ", ""))
          }
        }
      }
    }
  }
}

fun Card.imageRoute(): String? = when (supertype) {
  Supertype.POKEMON -> when {
    "TAG TEAM" in subtypes -> null
    else -> {
      val base = pokeName.lowercase()
        .replace(' ', '-')
        .replace(".", "")
      val regional = when {
        base.startsWith("alolan-") -> "${base.drop(7)}-alola"
        base.startsWith("paldean-") -> "${base.drop(8)}-paldea"
        base.startsWith("hisuian-") -> "${base.drop(8)}-hisui"
        base.startsWith("galarian-") -> "${base.drop(9)}-galar"
        else -> base
      }
      val final = when {
        subtypes.contains("MEGA") -> "$regional-mega"
        subtypes.contains("VMAX") && base in hasGmax -> "$regional-gmax"
        regional == "single-strike-urshifu" -> "urshifu-single-strike"
        regional == "rapid-strike-urshifu" -> "urshifu-rapid-strike"
        regional == "deoxys" -> "deoxys-attack"
        regional == "eiscue" -> "eiscue-noice"
        regional == "mr-rime-galar" -> "mr-rime"
        else -> regional
      }
      "assets/poke/$final.png"
    }
  }
  Supertype.TRAINER -> when {
    name.contains("Professor") && name.contains("Research") ->
      "assets/trainer/oak.png"
    name.contains("Boss") && name.contains("Orders") ->
      "assets/trainer/giovanni.png"
    name.endsWith(" Ball") -> {
      val elements = name.split(' ')
      val name = elements.dropLast(1).last().replace("é", "e").lowercase()
      "assets/trainer/$name.png"
    }
    else -> null
  }
  Supertype.ENERGY -> null
}

fun HtmlTag<HTMLElement>.withIcons(s: String) {
  val inBlocks =
    replacements.entries.fold(s) { current, (v, _) ->
      current.replace(v, "||$v||")
    }
  inBlocks.split("||").forEach {
    when (val r = replacements[it]) {
      null -> + it
      else -> span(baseClass = "font-symbol ${it.lowercase()}") { + r }
    }
  }
}

val replacements: Map<String, String> = mapOf(
  "Colorless" to "C",
  "Grass" to "G",
  "Fire" to "R",
  "Water" to "W",
  "Lightning" to "L",
  "Fighting" to "F",
  "Psychic" to "P",
  "Darkness" to "D",
  "Metal" to "M",
  "Fairy" to "Y",
)

val Type.usingFont: Char get() = when (this) {
  Type.COLORLESS -> 'C'
  Type.GRASS -> 'G'
  Type.FIRE -> 'R'
  Type.WATER -> 'W'
  Type.LIGHTNING -> 'L'
  Type.FIGHTING -> 'F'
  Type.PSYCHIC -> 'P'
  Type.DARKNESS -> 'D'
  Type.METAL -> 'M'
  Type.DRAGON -> 'N'
  Type.FAIRY -> 'Y'
}

private val hasGmax: List<String> = listOf(
  "alcremie",
  "appletun",
  "blastoise",
  "butterfree",
  "centiskorch",
  "charizard",
  "cinderace",
  "coalossal",
  "copperajah",
  "corviknight",
  "drednaw",
  "duraludon",
  "eevee",
  "flapple",
  "garbodor",
  "gengar",
  "grimmsnarl",
  "hatterene",
  "inteleon",
  "kingler",
  "lapras",
  "machamp",
  "melmetal",
  "meowth",
  "orbeetle",
  "pikachu",
  "rillaboom",
  "sandaconda",
  "snorlax",
  "urshifu-rapid-strike",
  "venusaur",
)
