package model

import kotlinx.serialization.Serializable
import kotlinx.serialization.SerialName

@Serializable
data class CardData(
  val data: Card
)

@Serializable
data class CardsData(
  val data: List<Card>
)

@Serializable
data class Card(
  val id: String,
  val name: String,
  val supertype: Supertype,
  val subtypes: List<String> = emptyList(),
  val hp: String? = null,
  val types: List<Type>? = null,
  val rules: List<String>? = null,
  val ancientTrait: Ability? = null,
  val abilities: List<Ability>? = null,
  val attacks: List<Attack>? = null,
  val weakness: WR? = null,
  val resistance: WR? = null,
  val retreatCost: List<Type>? = null,
  val regulationMark: String? = null,
  val images: CardImages,
  val set: CardSet,
  val nationalPokedexNumbers: List<Int>? = null
): Comparable<Card> {
  private val evolutionChainIds: List<Int>? =
    nationalPokedexNumbers?.map { evolutionChains[it - 1] }

  val baseName: String get() = when {
    name.startsWith("M ") && name.endsWith("-EX") ->
      name.drop(2).dropLast(3)
    name.endsWith(" V") ->
      name.dropLast(2)
    name.endsWith(" ex") || name.endsWith("-ex")
      || name.endsWith(" EX") || name.endsWith("-EX")
      || name.endsWith(" GX") || name.endsWith("-GX") ->
      name.dropLast(3)
    name.endsWith(" VMAX") ->
      name.dropLast(5)
    name.endsWith(" VSTAR") ->
      name.dropLast(6)
    name.endsWith(" V-UNION") ->
      name.dropLast(8)
    name.contains(" (") ->
      name.split(" (").first()
    else -> name
  }

  val pokeName: String get() = when {
    name == "Lance's Charizard V" ->
      "Charizard"
    name.endsWith(" on the Ball") ->
      name.dropLast(12)
    name.startsWith("Special Delivery ") ->
      name.drop(17)
    name.startsWith("Detective ") ->
      name.drop(10)
    name.startsWith("Radiant ") || name.startsWith("Shining ") ->
      name.drop(8)
    name.startsWith("Ash's ") ->
      name.drop(6)
    name.startsWith("Cool ") || name.startsWith("Dark ") ->
      name.drop(5)
    else -> this.baseName
  }

  override fun compareTo(other: Card): Int {
    if (this.id == other.id) return 0
    if (this.supertype != other.supertype)
      return this.supertype.compareTo(other.supertype)
    if ((this.supertype == Supertype.TRAINER && other.supertype == Supertype.TRAINER)
      || (this.supertype == Supertype.ENERGY && other.supertype == Supertype.ENERGY)) {
      val thisSubtypes = this.subtypes.joinToString()
      val otherSubtypes = other.subtypes.joinToString()
      if (thisSubtypes != otherSubtypes)
        return thisSubtypes.compareTo(otherSubtypes)
    }
    val thisChain = this.evolutionChainIds?.singleOrNull()
    val otherChain = other.evolutionChainIds?.singleOrNull()
    if (thisChain != null && otherChain != null && thisChain != otherChain)
      return thisChain.compareTo(otherChain)
    val thisHp = this.hp?.toIntOrNull()
    val otherHp = other.hp?.toIntOrNull()
    if (thisHp != null && otherHp != null && thisHp != otherHp)
      return thisHp.compareTo(otherHp)
    if (this.name != other.name)
      return this.name.compareTo(other.name)
    return this.id.compareTo(other.id)
  }
}

val STRIKES: List<String> =
  listOf("Fusion Strike", "Single Strike", "Rapid Strike")

@Serializable
enum class Supertype {
  @SerialName("Pokémon") POKEMON,
  @SerialName("Trainer") TRAINER,
  @SerialName("Energy") ENERGY,
}

@Serializable
enum class Type {
  @SerialName("Colorless") COLORLESS,
  @SerialName("Grass") GRASS,
  @SerialName("Fire") FIRE,
  @SerialName("Water") WATER,
  @SerialName("Lightning") LIGHTNING,
  @SerialName("Fighting") FIGHTING,
  @SerialName("Psychic") PSYCHIC,
  @SerialName("Darkness") DARKNESS,
  @SerialName("Metal") METAL,
  @SerialName("Dragon") DRAGON,
  @SerialName("Fairy") FAIRY,
}

@Serializable
data class WR(
  val type: Type,
  val value: String,
)

@Serializable
data class Ability(
  val name: String,
  val text: String,
  val type: String? = null
)

@Serializable
data class Attack(
  val cost: List<Type>,
  val name: String,
  val damage: String? = null,
  val text: String = "",
)

@Serializable
data class CardImages(
  val small: String,
  val large: String,
)

@Serializable
data class CardSet(
  val id: String,
  val name: String,
  val images: CardSetImages,
)

@Serializable
data class CardSetImages(
  val symbol: String,
  val logo: String,
)
