package app

import com.juul.indexeddb.*
import dev.fritz2.remote.http
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.*
import model.Card
import model.CardData
import model.CardsData
import model.DeckType
import org.w3c.dom.url.URLSearchParams

class CardDetailsEngine private constructor(
  private val database: Database
) {
  companion object {
    private const val DB: String = "deck-run"
    private const val TABLE: String = "cards"

    suspend operator fun invoke(): CardDetailsEngine {
      val database = openDatabase(DB, 1) { database, oldVersion, _ ->
        if (oldVersion < 1) {
          database.createObjectStore(TABLE, KeyPath("id"))
        }
      }
      return CardDetailsEngine(database)
    }
  }

  private val client =
    http("https://api.pokemontcg.io/v2").acceptJson()

  private val json =
    Json {
      ignoreUnknownKeys = true
      isLenient = true
    }

  @OptIn(ExperimentalSerializationApi::class)
  suspend fun card(id: String): Card? {
    // try to find in database
    val inDb: dynamic = database.transaction(TABLE) {
      objectStore(TABLE).get(Key(id))
    }
    return when {
      inDb != null ->
        json.decodeFromDynamic<Card>(inDb)
      else -> {
        // otherwise look on the internet
        val response = client.get("cards/$id")
        return when {
          response.ok -> {
            val data = json.decodeFromDynamic<CardData>(response.json()).data
            database.writeTransaction(TABLE) {
              objectStore(TABLE).put(Json.encodeToDynamic(data))
              data
            }
          }
          else -> null
        }
      }
    }
  }

  @OptIn(ExperimentalSerializationApi::class)
  suspend fun search(
    term: String,
    type: DeckType
  ): List<Card>? {
    val termWithName = when {
      ':' in term -> term
      else -> "name:\"$term\""
    }
    val termWithLegalities = when (type) {
      DeckType.STANDARD -> "$termWithName legalities.standard:legal (regulationMark:E OR regulationMark:F OR regulationMark:G)"
      DeckType.GLC -> "$termWithName legalities.expanded:legal -subtypes:V -subtypes:VMAX -subtypes:VSTAR -subtypes:EX -subtypes:GX -subtypes:BREAK -subtypes:Radiant -name:\"Spirit Link\""
      DeckType.EXPANDED -> "$termWithName legalities.expanded:legal"
    }
    val params = URLSearchParams().apply {
      append("q", termWithLegalities)
      append("pageSize", "45")
      append("orderBy", "-set.releaseDate,number")
    }
    val response = client.get("cards?$params")
    return when {
      response.ok -> {
        val data = json.decodeFromDynamic<CardsData>(response.json()).data
        database.writeTransaction(TABLE) {
          data.map {
            objectStore(TABLE).put(json.encodeToDynamic(it))
          }
          data
        }
      }
      else -> null
    }
  }
}
