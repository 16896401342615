package app.templates

import app.ApplicationDataStore
import dev.fritz2.core.*
import model.DeckType
import model.threeLetter
import org.w3c.dom.HTMLElement

fun RenderContext.header(store: ApplicationDataStore): Tag<HTMLElement> =
  header {
    div(baseClass = "header-box") {
      img(baseClass = "logo") {
        src("assets/logo.png")
      }

      div {
        inlineStyle("flex: 1 0 50px; margin-top: -0.3rem")
        input(baseClass = "deck-title") {
          type("deck")
          value(store.name.data)
        }
      }

      div(baseClass = "nes-badge") {
        store.type.data.render {
          val color = when (it) {
            DeckType.STANDARD -> "primary"
            DeckType.GLC -> "warning"
            DeckType.EXPANDED -> "success"
          }
          span(baseClass = "is-$color") {
           + it.threeLetter
          }
        }
      }

      div {
        store.showImages.data.render { showImages ->
          label {
            input(baseClass = "nes-checkbox", id = "show-images") {
              type("checkbox")
              checked(showImages)
              id("show-images")
              clicks.values() handledBy {
                store.showImages.update(!showImages)
              }
            }
            span {
              +"Images"
            }
            `for`("show-images")
          }
        }
      }
    }
  }
