package app.templates

import app.VariationStore
import app.numberOfCards
import app.only
import dev.fritz2.core.*
import model.*
import org.w3c.dom.HTMLElement

fun RenderContext.variationWithTitle(
  name: String,
  icon: String,
  cards: VariationStore
): Tag<HTMLElement> =
  section(baseClass = "big-nes-container with-title") {
    h3(baseClass = "title") {
      img(baseClass = "deck-image") { src("assets/trainer/$icon.png") }
      cards.numberOfCards.render { + "$name ($it cards)" }
    }
    variation(cards)
  }

fun RenderContext.variation(
  cards: VariationStore
): Tag<HTMLElement> =
  div(baseClass = "box") {
    block("Pokémon", cards, Supertype.POKEMON)
    block("Trainer", cards, Supertype.TRAINER)
    block("Energy", cards, Supertype.ENERGY)
    div(baseClass = "space-filler") { }
  }

fun RenderContext.block(
  title: String,
  cards: VariationStore,
  type: Supertype
): Tag<HTMLElement> =
  section(baseClass = "nes-container with-title") {
    h3(baseClass = "title") {
      cards.only(type).render { + "$title (${it.numberOfCards} cards)" }
    }
    cards.only(type).renderEach(idProvider =  { it.card.id }) {
      deckLine(cards, it)
    }
  }

fun RenderContext.deckLine(
  cards: VariationStore,
  line: DeckLine
): Tag<HTMLElement> = when (line.card) {
  is DeckCard.Ok ->
    card(cards.showImages, line.card.info) {
      cardUpDown(cards, line.number, line.card.info)
    }
  else -> details {
    summary { + "${line.number} ${line.card.id}" }
  }
}

fun RenderContext.cardUpDown(
  cards: VariationStore,
  number: Int,
  card: Card,
): Tag<HTMLElement> =
  input(baseClass = "up-down") {
    type("number")
    min("0")
    cards.type.data.render {
      max(cardMax(it, card).toString())
    }
    value("$number")
    changes.values() handledBy { newValue ->
      cards.updateAmount(card.id to { newValue.toInt() })
    }
  }

fun cardMax(
  type: DeckType,
  card: Card
) = when {
  card.supertype == Supertype.ENERGY && "Basic" in card.subtypes -> 60
  "Radiant" in card.subtypes -> 1
  type == DeckType.GLC -> 1
  else -> 4
}
