package model

// evolution chains obtained from https://pokeapi.co/
// Pokémon in the same "family" map to the same chain
val evolutionChains: IntArray = intArrayOf(
  1,
  1,
  1,
  2,
  2,
  2,
  3,
  3,
  3,
  4,
  4,
  4,
  5,
  5,
  5,
  6,
  6,
  6,
  7,
  7,
  8,
  8,
  9,
  9,
  10,
  10,
  11,
  11,
  12,
  12,
  12,
  13,
  13,
  13,
  14,
  14,
  15,
  15,
  16,
  16,
  17,
  17,
  18,
  18,
  18,
  19,
  19,
  20,
  20,
  21,
  21,
  22,
  22,
  23,
  23,
  24,
  24,
  25,
  25,
  26,
  26,
  26,
  27,
  27,
  27,
  28,
  28,
  28,
  29,
  29,
  29,
  30,
  30,
  31,
  31,
  31,
  32,
  32,
  33,
  33,
  34,
  34,
  35,
  36,
  36,
  37,
  37,
  38,
  38,
  39,
  39,
  40,
  40,
  40,
  41,
  42,
  42,
  43,
  43,
  44,
  44,
  45,
  45,
  46,
  46,
  47,
  47,
  48,
  49,
  49,
  50,
  50,
  51,
  52,
  53,
  54,
  54,
  55,
  55,
  56,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  64,
  65,
  66,
  67,
  67,
  67,
  67,
  68,
  69,
  69,
  70,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  76,
  76,
  77,
  78,
  79,
  79,
  79,
  80,
  80,
  80,
  81,
  81,
  81,
  82,
  82,
  83,
  83,
  84,
  84,
  85,
  85,
  17,
  86,
  86,
  10,
  14,
  16,
  87,
  87,
  88,
  88,
  89,
  89,
  89,
  18,
  90,
  90,
  91,
  26,
  92,
  92,
  92,
  93,
  94,
  94,
  95,
  96,
  96,
  67,
  67,
  97,
  33,
  98,
  99,
  100,
  101,
  102,
  102,
  103,
  104,
  41,
  105,
  105,
  106,
  58,
  107,
  108,
  109,
  110,
  110,
  111,
  111,
  112,
  112,
  113,
  114,
  114,
  115,
  116,
  117,
  118,
  118,
  54,
  119,
  119,
  68,
  120,
  121,
  47,
  47,
  59,
  60,
  61,
  122,
  51,
  123,
  124,
  125,
  126,
  126,
  126,
  127,
  128,
  129,
  130,
  130,
  130,
  131,
  131,
  131,
  132,
  132,
  132,
  133,
  133,
  134,
  134,
  135,
  135,
  135,
  135,
  135,
  136,
  136,
  136,
  137,
  137,
  137,
  138,
  138,
  139,
  139,
  140,
  140,
  140,
  141,
  141,
  142,
  142,
  143,
  143,
  143,
  144,
  144,
  144,
  145,
  145,
  145,
  146,
  146,
  90,
  147,
  148,
  148,
  149,
  150,
  151,
  151,
  151,
  152,
  152,
  153,
  153,
  154,
  155,
  156,
  157,
  158,
  159,
  159,
  160,
  160,
  161,
  161,
  162,
  162,
  163,
  164,
  164,
  165,
  166,
  166,
  166,
  167,
  167,
  168,
  168,
  169,
  170,
  171,
  172,
  173,
  173,
  174,
  174,
  175,
  175,
  176,
  176,
  177,
  177,
  178,
  178,
  179,
  180,
  181,
  181,
  182,
  182,
  183,
  184,
  185,
  100,
  186,
  186,
  187,
  187,
  187,
  188,
  188,
  188,
  189,
  190,
  191,
  191,
  191,
  192,
  192,
  192,
  193,
  194,
  195,
  196,
  197,
  198,
  199,
  200,
  201,
  202,
  203,
  203,
  203,
  204,
  204,
  204,
  205,
  205,
  205,
  206,
  206,
  206,
  207,
  207,
  208,
  208,
  209,
  209,
  209,
  158,
  158,
  211,
  211,
  212,
  212,
  213,
  213,
  213,
  214,
  214,
  215,
  216,
  216,
  217,
  217,
  218,
  218,
  93,
  219,
  219,
  220,
  220,
  98,
  97,
  221,
  221,
  184,
  223,
  223,
  224,
  224,
  91,
  57,
  51,
  228,
  229,
  230,
  230,
  230,
  72,
  232,
  232,
  233,
  233,
  234,
  234,
  235,
  235,
  236,
  237,
  237,
  116,
  239,
  239,
  109,
  34,
  48,
  50,
  52,
  60,
  61,
  87,
  95,
  67,
  67,
  104,
  112,
  68,
  140,
  147,
  182,
  186,
  240,
  241,
  242,
  243,
  244,
  245,
  246,
  247,
  248,
  249,
  250,
  250,
  252,
  253,
  254,
  255,
  256,
  256,
  256,
  257,
  257,
  257,
  258,
  258,
  258,
  259,
  259,
  260,
  260,
  260,
  261,
  261,
  262,
  262,
  263,
  263,
  264,
  264,
  265,
  265,
  266,
  266,
  266,
  267,
  267,
  268,
  268,
  268,
  269,
  269,
  270,
  270,
  271,
  272,
  272,
  272,
  273,
  273,
  273,
  274,
  275,
  276,
  276,
  276,
  277,
  277,
  277,
  278,
  278,
  279,
  279,
  280,
  281,
  281,
  281,
  282,
  282,
  283,
  284,
  284,
  285,
  285,
  286,
  287,
  287,
  288,
  288,
  289,
  289,
  290,
  290,
  291,
  291,
  292,
  292,
  293,
  293,
  293,
  294,
  294,
  294,
  295,
  295,
  296,
  296,
  296,
  297,
  297,
  298,
  299,
  299,
  300,
  300,
  301,
  301,
  302,
  303,
  303,
  304,
  304,
  305,
  305,
  305,
  306,
  306,
  306,
  307,
  307,
  308,
  308,
  308,
  309,
  309,
  309,
  310,
  310,
  311,
  312,
  312,
  313,
  314,
  314,
  315,
  316,
  316,
  317,
  317,
  318,
  319,
  319,
  320,
  320,
  321,
  322,
  323,
  323,
  323,
  324,
  324,
  325,
  326,
  327,
  328,
  329,
  330,
  331,
  332,
  333,
  334,
  335,
  336,
  337,
  337,
  337,
  338,
  338,
  338,
  339,
  339,
  339,
  340,
  340,
  341,
  341,
  341,
  342,
  342,
  342,
  343,
  343,
  344,
  344,
  344,
  345,
  345,
  346,
  346,
  347,
  348,
  348,
  349,
  349,
  349,
  350,
  350,
  351,
  351,
  352,
  352,
  353,
  353,
  354,
  354,
  355,
  355,
  356,
  356,
  357,
  357,
  358,
  358,
  67,
  359,
  360,
  361,
  362,
  362,
  362,
  363,
  364,
  364,
  365,
  365,
  366,
  366,
  367,
  367,
  368,
  369,
  370,
  371,
  372,
  373,
  374,
  374,
  374,
  375,
  375,
  375,
  376,
  376,
  376,
  377,
  377,
  377,
  378,
  378,
  379,
  379,
  379,
  380,
  380,
  381,
  382,
  382,
  383,
  383,
  384,
  385,
  385,
  386,
  386,
  387,
  387,
  388,
  388,
  389,
  389,
  390,
  390,
  391,
  391,
  392,
  392,
  392,
  393,
  394,
  395,
  396,
  396,
  397,
  397,
  398,
  399,
  399,
  400,
  401,
  402,
  403,
  404,
  405,
  406,
  407,
  408,
  408,
  408,
  409,
  410,
  411,
  412,
  413,
  413,
  413,
  413,
  414,
  415,
  416,
  417,
  418,
  419,
  420,
  421,
  422,
  423,
  424,
  424,
  425,
  426,
  427,
  428,
  429,
  430,
  430,
  430,
  431,
  431,
  431,
  432,
  432,
  432,
  433,
  433,
  434,
  434,
  434,
  435,
  435,
  435,
  436,
  436,
  437,
  437,
  438,
  438,
  439,
  439,
  440,
  440,
  441,
  441,
  441,
  442,
  442,
  442,
  443,
  443,
  444,
  445,
  445,
  446,
  446,
  447,
  447,
  448,
  448,
  449,
  449,
  450,
  450,
  450,
  451,
  451,
  451,
  134,
  22,
  113,
  35,
  57,
  287,
  452,
  452,
  453,
  454,
  455,
  455,
  456,
  457,
  458,
  459,
  460,
  460,
  461,
  462,
  463,
  464,
  465,
  466,
  466,
  466,
  467,
  468,
  469,
  470,
  470,
  471,
  472,
  473,
  474,
  475,
  476,
  120,
  58,
  110,
  280,
  109,
  106,
  477,
  478,
  478,
  478,
  479,
  479,
  479,
  480,
  480,
  480,
  481,
  481,
  482,
  482,
  483,
  483,
  484,
  484,
  484,
  485,
  485,
  486,
  486,
  487,
  487,
  487,
  488,
  489,
  489,
  489,
  490,
  490,
  490,
  491,
  491,
  492,
  492,
  493,
  493,
  494,
  494,
  495,
  495,
  496,
  496,
  497,
  498,
  498,
  499,
  499,
  500,
  500,
  501,
  501,
  501,
  502,
  502,
  503,
  504,
  504,
  505,
  505,
  506,
  507,
  508,
  508,
  509,
  509,
  510,
  511,
  511,
  512,
  513,
  514,
  24,
  96,
  101,
  103,
  317,
  515,
  516,
  517,
  518,
  519,
  520,
  521,
  522,
  523,
  524,
  525,
  526,
  527,
  527,
  527,
  528,
  528,
  529,
  530,
  531,
  532,
  533,
  534,
  535,
  536,
)
