package app.templates

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import dev.fritz2.core.target
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLElement

fun RenderContext.footer(): HtmlTag<HTMLElement> =
  footer {
    + "Developed by "
    link("https://serranofp.com/", "Alejandro Serrano")
    + " using "
    link("https://pokemontcg.io/", "Pokémon TCG API")
    + " & "
    link("https://www.fritz2.dev/", "fritz2")
    + " & "
    link("https://kotlinlang.org/lp/multiplatform/", "Kotlin/JS")
    + " & "
    link("https://nostalgic-css.github.io/NES.css/", "NES.css")
  }

fun RenderContext.link(
  where: String,
  title: String
): HtmlTag<HTMLAnchorElement> = a {
  href(where)
  target("_blank")
  + title
}
