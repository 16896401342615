package app

import dev.fritz2.core.Handler
import dev.fritz2.core.RootStore
import dev.fritz2.core.Store
import dev.fritz2.core.lensOf
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import model.*

data class ApplicationData(
  val showImages: Boolean = true,
  val search: SearchResults = SearchResults.None,
  val deck: Deck = Deck("Awesome Deck", emptyList()),
) {
  companion object {
    fun emptyDeck(
      type: DeckType,
      name: String = "Awesome Deck"
    ): ApplicationData =
      ApplicationData(deck = Deck(name, emptyList(), type = type))
  }
}

sealed interface SearchResults {
  object None: SearchResults
  data class Loading(
    val searchTerm: String
  ): SearchResults
  data class Found(
    val searchTerm: String,
    val cards: List<Card>,
  ): SearchResults
}

class ApplicationDataStore(
  internal val engine: CardDetailsEngine,
  initialData: ApplicationData = ApplicationData(),
) : RootStore<ApplicationData>(initialData) {

  companion object {
    suspend operator fun invoke(
      data: ApplicationData = ApplicationData()
    ): ApplicationDataStore =
      ApplicationDataStore(CardDetailsEngine(), data)

    suspend fun emptyDeck(
      type: DeckType
    ): ApplicationDataStore =
      ApplicationDataStore(CardDetailsEngine(), ApplicationData.emptyDeck(type))
  }

  val updateCardDetails: Handler<Pair<String, Card?>> = handle { data, (id, card) ->
    data.copy(
      deck = data.deck.copy(
        main = data.deck.main.updateCardDetails(id, card).sortedBy { it.card },
        support = data.deck.support.updateCardDetails(id, card).sortedBy { it.card },
      )
    )
  }

  val submitSearch: Handler<String> = handle { data, searchTerm ->
    coroutineScope {
      launch {
        obtainedSearchResults(searchTerm to engine.search(searchTerm, data.deck.type))
      }
    }
    data.copy(search = SearchResults.Loading(searchTerm))
  }

  private val obtainedSearchResults: Handler<Pair<String, List<Card>?>> = handle { data, (searchTerm, results) ->
    when {
      data.search is SearchResults.Loading && data.search.searchTerm == searchTerm ->
        data.copy(search = SearchResults.Found(searchTerm, results.orEmpty()))
      else -> data
    }
  }

  val showImages: Store<Boolean> =
    map(lensOf("showImages", { x -> x.showImages }, { x, i -> x.copy(showImages = i) }))

  val search: Store<SearchResults> =
    map(lensOf("search", { x -> x.search }, { x, s -> x.copy(search = s) }))

  private val deck: Store<Deck> =
    map(lensOf("deck", { x -> x.deck }, { x, c -> x.copy(deck = c) }))

  val type: Store<DeckType> =
    deck.map(lensOf("type", { x -> x.type }, { x, t -> x.copy(type = t) }))

  val name: Store<String> =
    deck.map(lensOf("name", { x -> x.name }, { x, n -> x.copy(name = n) }))

  val main: VariationStore =
    VariationStore(
      this,
      deck.map(lensOf("main", { x -> x.main }, { x, c -> x.copy(main = c) }))
    )

  val support: VariationStore =
    VariationStore(
      this,
      deck.map(lensOf("support", { x -> x.support }, { x, c -> x.copy(support = c) }))
    )
}

data class VariationStore(
  val mainStore: ApplicationDataStore,
  val variationStore: Store<List<DeckLine>>
) : Store<List<DeckLine>> by variationStore {
  val updateAmount: Handler<Pair<String, (Int?) -> Int>> =
    variationStore.handle { cards, (id, computeAmount) ->
      val (newCards, changed) = cards.updateAmount(id, computeAmount)
      if (changed) {
        coroutineScope {
          launch {
            mainStore.updateCardDetails(id to mainStore.engine.card(id))
          }
        }
      }
      newCards
    }

  val showImages: Store<Boolean> = mainStore.showImages
  val type: Store<DeckType> = mainStore.type
}

fun Store<List<DeckLine>>.only(type: Supertype) = data.map { lines ->
  lines.filter { it.card is DeckCard.Ok && it.card.info.supertype == type }
}

val Store<List<DeckLine>>.numberOfCards: Flow<Int>
  get() = data.map { it.numberOfCards }
