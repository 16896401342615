package model

data class Deck(
  val name: String,
  val main: List<DeckLine>,
  val support: List<DeckLine> = emptyList(),
  val type: DeckType = DeckType.STANDARD,
)

enum class DeckType {
  STANDARD,
  GLC,
  EXPANDED,
}

val DeckType.threeLetter: String get() = when (this) {
  DeckType.STANDARD -> "STD"
  DeckType.GLC -> "GLC"
  DeckType.EXPANDED -> "EXP"
}

val List<DeckLine>.numberOfCards: Int
  get() = sumOf { it.number }

fun List<DeckLine>.updateAmount(id: String, computeAmount: (Int?) -> Int = { 1 }): Pair<List<DeckLine>, Boolean> {
  val currentAmount = firstOrNull { it.card.id == id }
  val newAmount = computeAmount(currentAmount?.number)
  return when {
    newAmount <= 0 ->
      filterNot { it.card.id == id } to false
    any { it.card.id == id } ->
      map { if (it.card.id == id) it.copy(number = newAmount) else it } to false
    else ->
      this + DeckLine(newAmount, DeckCard.Loading(id)) to true
  }
}

fun List<DeckLine>.updateCardDetails(id: String, card: Card?): List<DeckLine> = map { line ->
  when {
    line.card.id != id -> line
    card == null -> line.copy(card = DeckCard.Problem(id))
    else -> line.copy(card = DeckCard.Ok(card))
  }
}

/**
 * A line in the deck, like "4 SV1-86"
 */
data class DeckLine(
  val number: Int,
  val card: DeckCard,
) {
  init {
    require(number > 0)
  }
}

/**
 * A card alongside its loaded status
 */
sealed interface DeckCard: Comparable<DeckCard> {
  val id: String

  data class Loading(
    override val id: String
  ) : DeckCard

  data class Problem(
    override val id: String
  ) : DeckCard

  data class Ok(
    val info: Card
  ) : DeckCard {
    override val id: String = info.id
  }

  override fun compareTo(other: DeckCard): Int = when {
    this is Ok && other !is Ok -> -1
    this !is Ok && other is Ok -> 1
    this is Ok && other is Ok -> this.info.compareTo(other.info)
    else -> this.id.compareTo(other.id)
  }
}
