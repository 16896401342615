package app

import app.templates.footer
import app.templates.header
import app.templates.search
import app.templates.variationWithTitle
import dev.fritz2.core.*
import model.DeckType

suspend fun main() {
  // initialize based on the browser url
  val deckType = when (kotlinx.browser.window.location.hash.drop(1)) {
    "glc" -> DeckType.GLC
    "expanded", "exp" -> DeckType.EXPANDED
    else -> DeckType.STANDARD
  }
  val store = ApplicationDataStore.emptyDeck(deckType)

  // this is the main part of the application
  render {
    header(store)
    div(baseClass = "boxes") {
      search(store)
      variationWithTitle("Main", "rule-book", store.main)
      store.support.variationStore.numberOfCards.render {
        if (it > 0) {
          variationWithTitle("Support", "rare-candy", store.support)
        }
      }
    }
    footer()
  }
}
